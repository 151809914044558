import {
  OnTrackStatus,
  PackageInstanceCard,
  PackageInstanceCardContentTypeEnum,
  PackageInstanceCardCyclesInner,
  PackageInstancePublic,
  PackageInstancePublicCyclesInner,
} from "@practice/sdk";
import { orderBy } from "lodash";
import { DateTime } from "luxon";
import moment from "moment";

import { getNormalizedDate } from "@lib/appointments";
import { AppointmentType } from "@lib/data/schemas/appointment";
import { PackageTimeType } from "@lib/data/schemas/packages";
import { getCurrentCycle } from "@lib/models/package-instances/utils";
import pluralHelper from "@lib/utils/pluralHelper";

/**
 * Formats the reset date
 * */
export const formatResetDate = (resetDate: any) =>
  moment(getNormalizedDate(resetDate)).utc().format("MMM DD");

export const getRecurringCycleTotal = (
  packageInstance?: PackageInstanceCard | PackageInstancePublic
) => {
  const isRollover = packageInstance?.isRollover;

  const currentCycle = getCurrentCycle(packageInstance);
  if (!currentCycle || isRollover) return packageInstance?.totalAccrued ?? 0;

  return currentCycle.totalAvailable;
};

export const getRecurringCycleTotalBooked = (
  packageInstance: PackageInstanceCard | PackageInstancePublic
) => {
  const currentCycle = getCurrentCycle(packageInstance);
  if (!currentCycle) return packageInstance.totalBooked;

  return currentCycle.totalBooked;
};

export const getTotalSessions = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  if (!packageInstance?.frequency) return packageInstance?.totalAvailable ?? 0;
  if (fromCycle) return fromCycle.totalAvailable;
  return packageInstance?.totalAvailable ?? 0;
};

const getTotalBooked = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  if (!packageInstance?.frequency) return packageInstance.totalBooked ?? 0;
  if (fromCycle) return fromCycle.totalBooked;
  return packageInstance?.totalBooked ?? 0;
};

/**
 * Get the total of appointment remaining given a package instance
 * */
export const getRemainingAppointmentsAcrossSchedulers = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
): number => {
  const totalSessions = getTotalSessions(packageInstance, fromCycle);

  const totalBooked = getTotalBooked(packageInstance, fromCycle);
  const remaining = totalSessions - totalBooked;
  return Math.max(0, remaining);
};

type PackageContentType = `${PackageInstanceCardContentTypeEnum}`;

/**
 * Returns some helper data from a package instance
 * */
type GetPackageInstanceHelper = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  contentType: PackageContentType;
  packageType: string;
  totalSessions: number;
  distributeSessions: boolean;
  timeType: PackageTimeType | null;
  isContentTypeSession: boolean;
  isContentTypeTime: boolean;
  isPackageTypeRecurring: boolean;
  isUsagePackage: boolean;
  isRollOver: boolean;
  isPackageLocked: boolean;
  isSubscriptionUBP: boolean;
  isCompleted: boolean;
};

export const getPackageInstanceHelper: GetPackageInstanceHelper = (
  packageInstance,
  fromCycle
) => {
  const {
    contentType = "sessions",
    packageType = "one-time",
    timeType = "hours",
  } = packageInstance ?? {};

  const isCompleted = packageInstance?.status === "completed";
  const isContentTypeSession = contentType === "sessions";
  const isContentTypeTime = contentType === "time";
  const isPackageTypeRecurring = packageType === "recurring";
  const isUsagePackage = packageType === "usage";
  const isRollOver = packageInstance?.isRollover ?? false;
  const isPackageLocked = packageInstance?.isLocked ?? false;
  const isSubscriptionUBP =
    isUsagePackage &&
    packageInstance?.usageInvoicing?.method === "subscription";

  const packageInstanceTotalApptsAvailable =
    packageInstance?.totalAvailable || 0;

  const recurringTotalAvailableSessions = fromCycle
    ? fromCycle.totalAvailable
    : packageInstanceTotalApptsAvailable;

  const totalSessions = isPackageTypeRecurring
    ? recurringTotalAvailableSessions
    : packageInstanceTotalApptsAvailable;

  const distributeSessions = isPackageTypeRecurring
    ? true
    : !!packageInstance?.distributeSessions;

  return {
    contentType,
    packageType,
    totalSessions,
    distributeSessions,
    timeType,
    isCompleted,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isRollOver,
    isPackageLocked,
    isSubscriptionUBP,
  };
};

/**
 * Returns data to identify wheter the package instance is from distribute
 * across schedulers
 * */
type GetPackageInstanceInfo = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  currentCycleOnly?: boolean,
  fromCycle?:
    | PackageInstanceCardCyclesInner
    | PackageInstancePublicCyclesInner
    | null
) => {
  distributeSessions: boolean;
  totalSessions: number;
  contentType: PackageContentType;
  totalRemainingAcrossSchedulers: number;
  totalAppointmentsRemainingInMinutes: number | null;
  totalAppointmentsConsumedInMinutes: number;
  totalSessionsConsumedInTotalAppointments: number;
  totalSessionsInMinutes: number;
  totalAppointmentsCompleted: number;
  totalAppointmentsCompletedInMinutes: number;
  isTotalRemainingAcrossSchedulersComplete: boolean;
  isContentTypeSession: boolean;
  isContentTypeTime: boolean;
  isPackageTypeRecurring: boolean;
  isUsagePackage: boolean;
  isRollOver: boolean;
  showSchedulerRemaining: boolean;
  formattedTimeConsumed: string;
  formattedRemaining: string;
  isCompleted: boolean;
  isPackageLocked: boolean;
  isSubscriptionUBP: boolean;
  hasRegularSchedulers: boolean;
};

export const isPackageInstancePaused = (
  packageInstance: PackageInstanceCard | PackageInstancePublic
) => {
  return !!packageInstance?.pausedOn;
};

export const getPackageInstanceTrackingInfo = (
  packageInstance: PackageInstanceCard | PackageInstancePublic
) => {
  const { onTrackStatus: status, onTrackHistory: history } =
    packageInstance ?? {};

  const isOffTrack = status === OnTrackStatus.OffTrack;
  const isAtRisk = status === OnTrackStatus.AtRisk;

  const orderedHistory = orderBy(history, "date", "desc");
  const lastStatusChange = orderedHistory?.find(
    (historyItem) => historyItem.action === "updatedStatus"
  );

  let offTrackSince: Date | null = null;
  let atRiskSince: Date | null = null;
  const changeDate = lastStatusChange?.date;

  if (changeDate) {
    if (OnTrackStatus.OffTrack === status) offTrackSince = changeDate;
    if (OnTrackStatus.AtRisk === status) atRiskSince = changeDate;
  }

  return {
    offTrackSince,
    atRiskSince,
    lastStatusChangeAt: changeDate,
    isOffTrack,
    isAtRisk,
    trackingStatus: status,
  };
};

/**
 * This fuction is used to convert a value to minutes if the value is less
 * than 1 hour. It returns a tuple with a boolean value to indicate if the
 * value is low and the converted value.
 * */
const getTimeValue = (value: number): [boolean, number] => {
  const isLow = value < 1.0;
  const data = isLow ? value * 60 : value;
  return [isLow, data];
};

/**
 * It converts a value to minutes, based on the package instance time settings.
 * */
export const getValueInMinutes = (
  packageInstance: PackageInstanceCard | PackageInstancePublic,
  value: number
) => {
  if (packageInstance?.contentType !== "time") {
    return null;
  }

  const { timeType } = packageInstance;
  if (timeType === "hours") {
    return value * 60;
  }
  return value;
};

export const getPackageInstanceInfo: GetPackageInstanceInfo = (
  packageInstance,
  currentCycleOnly = false,
  fromCycle
) => {
  const targetCycle = fromCycle
    ? fromCycle
    : currentCycleOnly
    ? getCurrentCycle(packageInstance)
    : null;

  const {
    contentType,
    totalSessions,
    distributeSessions,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isRollOver,
    isPackageLocked,
    isSubscriptionUBP,
    isCompleted,
  } = getPackageInstanceHelper(
    packageInstance as PackageInstanceCard,
    targetCycle
  );

  const totalRemainingAcrossSchedulers =
    getRemainingAppointmentsAcrossSchedulers(packageInstance, targetCycle);

  /**
   * We need to convert the this value to minutes because the scheduler
   * duration has its value in minutes, and we check if the appointment
   * can be booked based on the scheduler duration.
   * */
  const totalAppointmentsRemainingInMinutes = getValueInMinutes(
    packageInstance,
    totalRemainingAcrossSchedulers
  );

  const showSchedulerRemaining =
    !distributeSessions && isContentTypeSession && !isUsagePackage;

  const totalSessionsConsumedInTotalAppointments = targetCycle
    ? targetCycle.totalBooked
    : packageInstance.totalBooked;

  const totalAppointmentsConsumedInMinutes =
    totalSessionsConsumedInTotalAppointments;

  const totalAppointmentsCompleted = targetCycle
    ? targetCycle.totalCompleted ?? 0
    : packageInstance.totalCompleted;

  const totalAppointmentsCompletedInMinutes = totalAppointmentsCompleted;

  const isTimeMinutesBased =
    isContentTypeTime && packageInstance?.timeType === "minutes";

  const packageItems = (packageInstance?.items ||
    []) as PackageInstanceCard["items"];
  const totalSessionsFromSchedulersQuantity = packageItems?.reduce(
    (acc, scheduler) => {
      const { quantity = 0 } = scheduler;
      return acc + quantity;
    },
    0
  );

  const isTotalRemainingAcrossSchedulersComplete =
    totalRemainingAcrossSchedulers === 0;

  // formatters
  const [isConsumedLow, consumedTime] = getTimeValue(
    totalSessionsConsumedInTotalAppointments
  );
  const [isRemaininLow, remainingTime] = getTimeValue(
    totalRemainingAcrossSchedulers
  );

  const formattedTimeConsumed =
    isTimeMinutesBased || isConsumedLow
      ? formatInMinutes(consumedTime)
      : formatDuration(totalAppointmentsConsumedInMinutes, false);

  const formattedTimeRemaining =
    isTimeMinutesBased || isRemaininLow
      ? formatInMinutes(remainingTime)
      : formatDuration(remainingTime, false);

  const formattedRemaining = isContentTypeSession
    ? pluralHelper(totalRemainingAcrossSchedulers, "appointment")
    : formattedTimeRemaining;

  const hasRegularSchedulers = packageInstance?.items?.some(
    (item) => !item.curriculum
  );

  return {
    distributeSessions,
    totalSessions: distributeSessions
      ? totalSessions
      : totalSessionsFromSchedulersQuantity,
    contentType,
    totalRemainingAcrossSchedulers,
    totalAppointmentsRemainingInMinutes,
    totalAppointmentsConsumedInMinutes,
    totalSessionsConsumedInTotalAppointments,
    totalSessionsInMinutes: totalSessions,
    totalAppointmentsCompleted,
    totalAppointmentsCompletedInMinutes,
    isTotalRemainingAcrossSchedulersComplete,
    isContentTypeSession,
    isContentTypeTime,
    isPackageTypeRecurring,
    isUsagePackage,
    isCompleted,
    isRollOver,
    showSchedulerRemaining,
    formattedTimeConsumed: formattedTimeConsumed || "0",
    formattedRemaining,
    isPackageLocked,
    isSubscriptionUBP,
    hasRegularSchedulers,
  };
};

/**
 * Get the total appointments consumed in minutes or seconds
 * */
export const getTotalAppointmentsConsumed = (
  appointments: AppointmentType[],
  format: "minutes" | "seconds" = "minutes"
) => {
  const getDateTime = (date: any) => {
    const normalizedDate = getNormalizedDate(date);
    return DateTime.fromJSDate(normalizedDate);
  };

  const result = appointments?.reduce((acc, item) => {
    const { start, end } = item;
    const startDate = getDateTime(start);
    const endDate = getDateTime(end);
    const diff = endDate.diff(startDate, format)[format];
    return acc + diff;
  }, 0);

  return result;
};

/**
 * Formats a duration in minutes to a human readable format
 * */
export const formatDuration = (value: number, shouldDivide: boolean = true) => {
  const hours = shouldDivide ? value / 60 : value;
  const calculatedStringValue = hours.toString();
  const floatedHours = parseFloat(calculatedStringValue).toFixed(2);
  const splitted = floatedHours.split(".");
  let formattedFloatHours;

  if (splitted[1] === "0") {
    formattedFloatHours = splitted[0];
  } else {
    formattedFloatHours = parseFloat(floatedHours);
  }

  let formattedDuration = "";

  if (hours > 0) {
    formattedDuration += `${formattedFloatHours} hours`;
    if (hours === 1) {
      formattedDuration = formattedDuration.replace("hours", "hour");
    }
  }

  return formattedDuration;
};

/**
 * Formats a duration in minutes to a human readable format
 * */
export const formatInMinutes = (value: number) => `${value} minutes`;
